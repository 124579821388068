import React, { SVGProps } from "react";

export const JFLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="96"
    height="22"
    viewBox="0 0 96 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.8626 15.8124C4.8626 17.6707 4.05216 18.8277 2.1366 18.8277C1.39984 18.8277 1.03146 18.7926 0.331541 18.6524L0 21.5624C0.84727 21.7027 1.58403 21.7728 2.61549 21.7728C6.5203 21.7728 8.25168 19.3536 8.25168 15.8826V0.455792H4.8626V15.8124Z"
      fill="currentColor"
    />
    <path
      d="M23.1845 21.4923H26.3894V5.11889H23.1845V14.8307C23.1845 17.5655 21.4163 19.1082 18.4693 19.1082C15.3749 19.1082 14.3434 17.0746 14.3434 14.8307V5.11889H11.1386V15.6021C11.1386 19.1082 12.6857 21.913 17.1431 21.913C20.1638 21.913 22.4109 20.4054 23.1477 17.0746H23.1845V21.4923Z"
      fill="currentColor"
    />
    <path
      d="M36.1592 21.913C40.8008 21.913 43.0111 19.9496 43.0111 16.9695C43.0111 14.4451 41.2797 12.8323 36.5644 12.0609C33.028 11.5 32.1439 10.6585 32.1439 9.29113C32.1439 7.92376 33.2859 7.08229 35.4593 7.08229C37.7433 7.08229 38.8852 8.064 39.5115 10.5183L42.569 9.81704C41.7954 6.6265 39.8798 4.69816 35.4593 4.69816C31.1493 4.69816 28.939 6.6265 28.939 9.46643C28.939 12.0259 30.5967 13.5686 35.1278 14.375C38.8115 14.971 39.843 15.6722 39.843 17.0396C39.8062 18.5472 38.6274 19.4938 36.1961 19.4938C32.9543 19.4938 31.7755 17.846 31.1861 15.6021L28.1654 16.1631C28.9022 19.564 30.8177 21.913 36.1592 21.913Z"
      fill="currentColor"
    />
    <path
      d="M46.1377 16.6189C46.1377 19.6691 47.7217 21.913 51.3318 21.913C52.9158 21.913 53.8368 21.7027 55.0156 21.3521L54.6104 18.5472C53.6894 18.7926 52.9158 18.9679 51.9949 18.9679C50.0793 18.9679 49.3426 18.0213 49.3426 16.1981V7.74845H54.8314V5.11889H49.3426V0.596035H46.1377V5.11889H43.5959V7.74845H46.1377V16.6189Z"
      fill="currentColor"
    />
    <path
      d="M70.2788 12.8323V9.78198H60.1484V3.50609H71.4945V0.455792H56.7593V21.4923H60.1484V12.8323H70.2788Z"
      fill="currentColor"
    />
    <path
      d="M77.8197 2.5945V0H74.1727V2.5945H77.8197ZM77.5618 21.4923V5.11889H74.3937V21.4923H77.5618Z"
      fill="currentColor"
    />
    <path
      d="M92.2794 21.4923H96V21.4222L90.1059 13.4283L95.8526 5.18901V5.11889H92.3531L87.7852 12.096L83.1068 5.11889H79.3861V5.18901L85.1697 13.0777L79.2756 21.4222V21.4923H82.8857L87.4536 14.41L92.2794 21.4923Z"
      fill="currentColor"
    />
  </svg>
);
