import React, { SVGProps } from "react";

export const DotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="9.5" cy="10.1751" r="1.5" fill="currentcolor" />
  </svg>
);

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 2L14 14" stroke="currentcolor" strokeLinecap="square" />
    <path d="M14 2L2 14" stroke="currentcolor" strokeLinecap="square" />
  </svg>
);

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    width="18"
    height="14"
    viewBox="0 0 18 14"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="currentcolor"
      strokeWidth="2"
      d="M1.66675 7.01497L6.33342 11.6816L16.3334 1.68164"
    />
  </svg>
);

export const SubscribedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" width="30" height="30" rx="15" fill="#1AA551" />
    <path d="M8 15.3333L12.6667 20L22.6667 10" stroke="#F2F2F2" strokeWidth="2" />
  </svg>
);

export const ChevronIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" viewBox="0 -2 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.5 1L5.25 5.25L1 1" stroke="currentcolor" />
  </svg>
);

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="0.5" width="16" height="17" rx="8" fill="none" />
    <path
      d="M6.84 12V11.11H7.98V8.24H6.9V7.35H9.12V11.11H10.16V12H6.84ZM8.5 6.58C8.30667 6.58 8.14 6.51333 8 6.38C7.86 6.24667 7.79 6.08667 7.79 5.9C7.79 5.7 7.85667 5.53667 7.99 5.41C8.12333 5.28333 8.29333 5.22 8.5 5.22C8.68667 5.22 8.85 5.28667 8.99 5.42C9.13667 5.55333 9.21 5.71333 9.21 5.9C9.21 6.08667 9.13667 6.24667 8.99 6.38C8.85 6.51333 8.68667 6.58 8.5 6.58Z"
      fill="currentcolor"
    />
    <rect x="0.5" y="0.5" width="16" height="17" rx="8" stroke="currentcolor" />
  </svg>
);

export const ShowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="27"
    height="19"
    viewBox="0 0 27 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.1204 9.04301C24.2588 3.63395 19.0208 0 13.0856 0C7.15035 0 1.91226 3.63367 0.0506896 9.04301C-0.0168965 9.23969 -0.0168965 9.45419 0.0506896 9.65088C1.91231 15.0602 7.15035 18.6939 13.0856 18.6939C19.0208 18.6939 24.2588 15.0602 26.1204 9.65088C26.188 9.45398 26.188 9.2397 26.1204 9.04279V9.04301ZM13.0856 16.8242C8.06077 16.8242 3.61916 13.8329 1.92839 9.34678C3.61921 4.86071 8.0611 1.86936 13.0856 1.86936C18.11 1.86936 22.5519 4.86066 24.2427 9.34678C22.5519 13.8328 18.11 16.8242 13.0856 16.8242Z"
      fill="#FAF8F4"
    />
    <path
      d="M13.0855 3.73871C9.99299 3.73871 7.47748 6.25427 7.47748 9.34678C7.47748 12.4393 9.99304 14.9548 13.0855 14.9548C16.178 14.9548 18.6936 12.4393 18.6936 9.34678C18.6936 6.25427 16.178 3.73871 13.0855 3.73871ZM13.0855 13.0855C11.024 13.0855 9.34689 11.4086 9.34689 9.34683C9.34689 7.28525 11.024 5.60818 13.0855 5.60818C15.1471 5.60818 16.8242 7.28531 16.8242 9.34683C16.8242 11.4088 15.1471 13.0855 13.0855 13.0855Z"
      fill="#FAF8F4"
    />
  </svg>
);

export const HideIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.0663 20.4476C23.299 18.8761 25.0351 16.653 25.9496 13.9961C26.0168 13.8007 26.0168 13.5876 25.9496 13.3922C24.1 8.01819 18.8964 4.40815 13.0001 4.40815C10.6985 4.40815 8.5062 4.96431 6.57418 5.95452L1.58525 0.965924C1.22253 0.603196 0.635076 0.603196 0.272329 0.965924C-0.0904189 1.32865 -0.0903912 1.91612 0.272329 2.27888L4.93372 6.94036C2.70102 8.51177 0.964937 10.735 0.050358 13.3918C-0.016786 13.5872 -0.016786 13.8003 0.050358 13.9957C2.7265 21.7715 12.0161 25.2305 19.4258 21.4332L24.4143 26.4218C24.777 26.7846 25.3645 26.7846 25.7272 26.4218C26.09 26.0591 26.0899 25.4716 25.7272 25.1089L21.0663 20.4476ZM12.9999 6.2651C17.9919 6.2651 22.4044 9.2369 24.0841 13.6938C23.2367 15.9418 21.6927 17.8105 19.7313 19.112L17.5361 16.9168C18.1851 16.0059 18.5712 14.8951 18.5712 13.6938C18.5712 10.6214 16.0721 8.12226 12.9998 8.12226C11.7986 8.12226 10.6877 8.50831 9.77684 9.15752L7.96431 7.34496C9.50352 6.65145 11.2131 6.2651 12.9999 6.2651ZM9.8121 11.8189L14.8746 16.8818C14.3226 17.208 13.6867 17.408 12.9999 17.408C10.9519 17.408 9.28575 15.742 9.28575 13.6937C9.28575 13.0069 9.48565 12.3714 9.8121 11.8189ZM11.1252 10.5055C11.6777 10.1793 12.3131 9.97935 12.9999 9.97935C15.048 9.97935 16.7141 11.6455 16.7141 13.6936C16.7141 14.3804 16.5142 15.0163 16.188 15.5688L11.1252 10.5055ZM1.91589 13.6937C2.76331 11.4456 4.30727 9.57693 6.26875 8.27542L8.46386 10.4706C7.81486 11.3815 7.42879 12.4924 7.42879 13.6937C7.42879 16.7661 9.92789 19.2652 13.0002 19.2652C14.2014 19.2652 15.3123 18.8791 16.2232 18.2299L18.0357 20.0425C11.7789 22.8601 4.28206 19.9723 1.91589 13.6937Z"
      fill="#FAF8F4"
    />
  </svg>
);

export const AlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 14C3.14 14 0 10.8599 0 7C0 3.14007 3.14007 0 7 0C10.8599 0 14 3.14007 14 7C14 10.84 10.8599 14 7 14Z"
      fill="#FF813A"
    />
    <path
      d="M7 8.75C6.50422 8.75 6.125 8.37612 6.125 7.88729V3.48771C6.125 2.9989 6.50421 2.625 7 2.625C7.49579 2.625 7.875 2.99888 7.875 3.48771V7.88729C7.875 8.3763 7.49579 8.75 7 8.75Z"
      fill="#FAF8F4"
    />
    <path
      d="M6.97194 11.375C6.74608 11.375 6.52022 11.2879 6.37902 11.1136C6.20967 10.9394 6.125 10.736 6.125 10.5036C6.125 10.4455 6.125 10.3875 6.15316 10.3294C6.15316 10.2712 6.18132 10.2133 6.20967 10.1551C6.23783 10.097 6.26619 10.068 6.29435 10.0099C6.3225 9.95174 6.35086 9.92277 6.40718 9.86462C6.71771 9.54513 7.28225 9.54513 7.59282 9.86462C7.62098 9.89359 7.6775 9.95174 7.70565 10.0099C7.73381 10.068 7.76217 10.097 7.79033 10.1551C7.81849 10.2133 7.81849 10.2712 7.84684 10.3294C7.84684 10.3875 7.875 10.4455 7.875 10.5036C7.875 10.736 7.79033 10.9684 7.62098 11.1136C7.42367 11.2879 7.19781 11.375 6.97195 11.375H6.97194Z"
      fill="#FAF8F4"
    />
  </svg>
);

export const AlertIconOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 18C5.58857 18 2 14.4113 2 10C2 5.58865 5.58865 2 10 2C14.4113 2 18 5.58865 18 10C18 14.3886 14.4113 18 10 18ZM10 3.34857C6.34278 3.34857 3.37143 6.32004 3.37143 9.97714C3.37143 13.6344 6.3429 16.6057 10 16.6057C13.6572 16.6057 16.6286 13.6342 16.6286 9.97714C16.6286 6.32004 13.6573 3.34857 10 3.34857Z"
      fill="#FAF8F4"
    />
    <path
      d="M9.99996 11.1657C9.61141 11.1657 9.31421 10.8685 9.31421 10.48V6.98281C9.31421 6.59426 9.6114 6.29706 9.99996 6.29706C10.3885 6.29706 10.6857 6.59425 10.6857 6.98281V10.48C10.6857 10.8687 10.3885 11.1657 9.99996 11.1657Z"
      fill="#FAF8F4"
    />
    <path
      d="M9.99997 13.68C9.81709 13.68 9.63422 13.6114 9.51989 13.4743C9.38277 13.3372 9.31421 13.1771 9.31421 12.9942C9.31421 12.9485 9.31421 12.9029 9.33701 12.8571C9.33701 12.8114 9.35981 12.7658 9.38277 12.72C9.40557 12.6743 9.42853 12.6514 9.45133 12.6057C9.47413 12.5599 9.49709 12.5371 9.54269 12.4914C9.79412 12.2399 10.2512 12.2399 10.5027 12.4914C10.5255 12.5142 10.5712 12.5599 10.594 12.6057C10.6168 12.6514 10.6398 12.6743 10.6626 12.72C10.6854 12.7658 10.6854 12.8114 10.7084 12.8571C10.7084 12.9029 10.7312 12.9485 10.7312 12.9942C10.7312 13.1771 10.6626 13.36 10.5255 13.4743C10.3657 13.6114 10.1829 13.68 9.99998 13.68H9.99997Z"
      fill="#FAF8F4"
    />
  </svg>
);

export const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="80"
    height="81"
    viewBox="0 0 80 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.643311" width="80" height="80" rx="40" fill="#242323" />
    <path
      d="M61 29.9605C61.0007 29.176 60.748 28.4108 60.2765 27.7695C60.2086 27.5735 60.0665 27.4089 59.8781 27.3082C59.13 26.5634 58.0978 26.143 57.0196 26.1433H22.9805C21.9253 26.1446 20.9133 26.547 20.1672 27.2628C19.421 27.9784 19.0013 28.9485 19 29.9604V51.3256C19.001 52.3378 19.4207 53.3079 20.1672 54.0238C20.9133 54.7393 21.9249 55.1421 22.9805 55.1433H57.0196C58.0978 55.1436 59.1294 54.7232 59.8778 53.9791C60.0662 53.8781 60.2086 53.7132 60.2765 53.5165C60.7483 52.8756 61.001 52.11 61 51.3256L61 29.9605ZM46.6017 40.6434L59.1923 29.4014C59.2435 29.5839 59.2694 29.7718 59.2694 29.9606V51.3257C59.2694 51.5148 59.2435 51.703 59.1923 51.8855L46.6017 40.6434ZM57.0201 27.8024C57.4089 27.8024 57.7915 27.8993 58.1295 28.0841L41.8353 42.6336C41.3483 43.0345 40.7256 43.251 40.0841 43.2428C39.4423 43.2346 38.826 43.0018 38.3506 42.5886L22.0202 28.0101C22.3205 27.8729 22.6489 27.8021 22.9813 27.8024L57.0201 27.8024ZM20.86 52.0458C20.7747 51.8145 20.7311 51.5713 20.7314 51.3261V29.961C20.7311 29.7162 20.7747 29.4729 20.86 29.242L33.6288 40.6443L20.86 52.0458ZM22.9817 53.4847C22.6493 53.485 22.3209 53.4142 22.0207 53.277L34.8978 41.7772L37.1694 43.8071C37.9779 44.5198 39.0373 44.9128 40.1353 44.9078C41.1991 44.9159 42.2267 44.5374 43.0113 43.8486L45.3318 41.7772L58.1299 53.2029C57.7919 53.3876 57.4094 53.4845 57.0205 53.4845L22.9817 53.4847Z"
      fill="#F2F2F2"
    />
  </svg>
);

type ArrowIconProps = {
  dir: "up" | "down" | "both";
};

export const ArrowIcon = (props: ArrowIconProps) => {
  // TODO: we should make and SVG for this arrow to ensure it looks the same across fonts, has the same baseline up or down
  return (
    <span className="arrow-icon">
      {props.dir === "up" ? (
        <span className="arrow-1">↑</span>
      ) : props.dir === "down" ? (
        <span className="arrow-1">↓</span>
      ) : (
        <>
          <span className="arrow-1">↑</span>
          <span className="arrow-2">↓</span>
        </>
      )}
    </span>
  );
};

export const AddIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.30029 0.751953C3.88252 0.751953 0.300293 4.33418 0.300293 8.75195C0.300293 13.1697 3.88252 16.752 8.30029 16.752C12.7181 16.752 16.3003 13.1697 16.3003 8.75195C16.3003 4.33418 12.7181 0.751953 8.30029 0.751953ZM12.367 9.68529H9.18918V12.8675C9.18918 13.3697 8.78029 13.7786 8.27807 13.7786C7.77585 13.7786 7.36696 13.3697 7.36696 12.8675V9.68529H4.18474C3.68251 9.68529 3.27363 9.2764 3.27363 8.77418C3.27363 8.27195 3.68251 7.86306 4.18474 7.86306H7.36696V4.68084C7.36696 4.17862 7.77585 3.76973 8.27807 3.76973C8.78029 3.76973 9.18918 4.17862 9.18918 4.68084V7.86306H12.3714C12.8736 7.86306 13.2825 8.27195 13.2825 8.77418C13.2825 9.2764 12.8692 9.68529 12.367 9.68529Z"
      fill="#FAF8F4"
    />
  </svg>
);
